import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { getSearchParams } from "gatsby-query-params";
import { Modal, Accordion, Card, Button } from "react-bootstrap";
// import App from "./App"

import SalesPage from "../services/data/sales-page.json";
import VideoModalBs from "../components/section/modalVideo/video"


function Index() {
  const searchParams = getSearchParams();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Video = ({ onSubmit }) => {
    return (
      <div className="wrape-video-modal">
        <iframe
          src="https://www.youtube.com/embed/yq2ahfRFk20?autoplay=1&mute=0&enablejsapi=1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    );
  };

  const onCloseClickVideo = (e) => {
    e.preventDefault();
    handleClose();
  };

  const handleClickScroll = () => {
    let element = document.getElementById("pelajari");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickScrollToPriceList = () => {
    let elementPrice = document.getElementById("pricehlg");
    if(elementPrice){
      elementPrice.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Buku Happy Love Guide</title>
      </Helmet>
      {/* <App/> */}
      <div className="main">
        <div className="container __bg-primary-new-hlg">
          <div className="d-flex flex-column justify-content-center">
            <p className="text-center text-white __text-w-22">
              Sudah Terjual 4.229+ Copy
            </p>
            <div className="my-3 mx-auto">
              <img
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/logo-hlg.png"
                className="img img-fluid"
                alt="logo hlg"
              />
            </div>
            <p className="text-center text-white __text-w-36">
              Panduan Temukan Pria Tepat <br /> Dengan Menyenangkan
            </p>
            <div className="my-3 mx-auto">
              <img
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/video-1.png"
                className="img img-fluid"
                alt="video 1"
                onClick={handleShow}
                style={{ cursor: "pointer" }}
              />
              <div className="my-3 d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-danger __border-radius-100 py-3"
                  onClick={handleClickScroll}
                  style={{ cursor: "pointer" }}
                >
                  <span className="px-5 __text-w-32">
                    Pelajari Selengkapnya
                  </span>
                </button>
              </div>
            </div>
            <div className="my-5 mx-auto">
              <img
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/white-hlg-down-arrow.png"
                alt="arrow"
              />
            </div>
            <div className="mx-auto" id="pelajari">
              {SalesPage.testimoni1.map((items, index) => (
                <div
                  className="card p-3 my-3 __border-radius-20 __w-h-540-502"
                  key={index}
                >
                  <div className="mx-auto d-flex align-items-center h-100">
                    <img
                      src={items.image}
                      className="img img-fluid"
                      alt={`testimoni-${index}`}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="my-5 mx-auto">
              <p className="text-center text-white __text-w-40 pb-3">
                Sudah Terjual 4.229+ Copy
              </p>
              <div className="d-flex justify-content-center">
                <img
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/mockup-buku-hlg.png"
                  className="img img-fluid"
                  alt="mockup buku"
                />
              </div>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-danger __border-radius-100 mt-5 py-3"
                  onClick={handleClickScrollToPriceList}
                >
                  <span className="px-3 __text-w-22">
                    SAYA MAU PUNYA HAPPY LOVE GUIDE
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-0 m-0 __bg-primary-new-hlg">
          <div className="d-flex justify-content-between w-100">
            <img
              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/aksen/aksen-kiri.png"
              className="img img-fluid"
              alt="akesn kiri"
            />
            <img
              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/aksen/aksen-kanan.png"
              className="img img-fluid"
              alt="akesn kanan"
            />
          </div>
        </div>
        <div className="container bg-white border">
          <div className="d-flex flex-column py-5">
            <p className="text-center text-drak __text-w-36">
              Siapa Yang Membutuhkan “PANDUAN” ini?
            </p>
            <div className="my-3">
              <div className="card __border-radius-30 __box-shadow-card py-4 px-4 __w-90 mx-auto">
                {SalesPage.guideline.map((items, index) => (
                  <div className="d-flex flex-row py-1 gap-flex-3" key={index}>
                    <div className="__w-10">
                      <img
                        src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/icon/checklist.png"
                        className="img img-fluid"
                        width={"50px"}
                        height={"50px!important"}
                        alt="icon checklist"
                      />
                    </div>
                    <div
                      className="__w-90"
                      dangerouslySetInnerHTML={{ __html: items.text }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="d-flex flex-column py-5">
            <p className="text-center text-dark __text-w-36">
              Perkenalkan Buku Happy <br /> Love Guide
            </p>
            <div className="my-5 mx-auto">
              <img
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/photo-1-new.png"
                className="img img-fluid"
                alt="photo new 1"
              />
            </div>
            <div className="__w-90 mx-auto">
              <p className="text-center text-dark __text-w-22-light">
                <b>Buku Happy Love Guide</b> merangkum semua mindset, strategi
                dan langkah yang bisa mengantarkan kamu{" "}
                <b>mencintai diri sendiri, </b>
              </p>
              <br />
              <p className="text-center text-dark __text-w-22-light">
                menciptakan{" "}
                <b>kebahagiaan sejak single hingga bertemu pria yang tepat</b>{" "}
                tanpa harus ngejar atau menurunkan standar.
              </p>
              <br />
              <p className="text-center text-dark __text-w-22-light">
                Membaca habis buku ini membuatmu yang awalnya "buta" jadi
                benar-benar tahu apa yang perlu kamu lakukan untuk bertemu pria
                yang tepat dan menciptakan hubungan penuh kebahagiaan.
              </p>
              <br />
              <p className="text-center text-dark __text-w-22-light">
                <span style={{ background: "#FFD600" }}>
                  Buku ini <b>tidak dijual di toko buku manapun.</b>
                </span>
              </p>
              <br />
              <p className="text-center text-dark __text-w-22-light">
                Ditulis langsung oleh Jose Aditya, Love Life Coach untuk wanita
                nomor 1 di Indonesia.
              </p>
              <br />
              <p className="text-center text-dark __text-w-22-light">
                Berdasarkan 10 tahun pengalaman membantu ratusan klien dan
                ribuan member. Membongkar strategi romansa yang efektif, minim
                drama dan cocok untuk wanita Indonesia.
              </p>
              <br />
              <p className="text-center text-dark __text-w-22-light">
                <b>Dengan formula yang terbukti efektif.</b>
                <br />
                <b>KHUSUS untuk KAMU</b> yang capek berjuang sendirian dan ingin
                berhasil di kehidupan romansa.
              </p>
            </div>
          </div>
        </div>
        <div className="conatiner __bg-secondary-new-hlg">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-center my-5">
              <img
                className="img img-fluid"
                width={300}
                height={300}
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/ava-jose-min.png"
                alt="jose"
              />
            </div>
            <p className="text-center text-white __text-w-36 m-0 p-0">
              Jose Aditya, Love Life Coach
            </p>
            <p className="text-center text-white __text-w-22-light">
              Pelatih Percintaan Wanita #1 di Indonesia.
            </p>
            <br />
            <p className="text-center text-white __text-w-22-light">
              Awalnya Jose ingin menikah namun “terhalang” kondisi kakak
              perempuan yang berusia 30+ tapi belum kunjung punya calon.
            </p>
            <br />
            <p className="text-center text-white __text-w-22-light">
              Dengan niat membantu sang kakak perempuan untuk menggapai cinta,
              Jose Aditya melakukan riset mendalam mengenai kehidupan cinta
              wanita dari aspek psikologis, biologis, dan neuroscience.
            </p>
            <br />
            <p className="text-center text-white __text-w-22-light">
              Selama kurang lebih 6 bulan intens mengikuti seminar, workshop,
              training dan juga coaching dari Jose, sang kakak akhirnya berhasil
              menemukan pria idaman.
            </p>
            <div className="d-flex justify-content-center py-5">
              <img
                className="img img-fluid"
                width={617}
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/1632285008980.jpg"
                alt="photo new 2"
              />
            </div>
            <p className="text-center text-white __text-w-22-light">
              Setelah berhasil membantu sang kakak, ia sadar jutaan wanita juga
              punya masalah serupa. Impiannya kini adalah membantu 1 juta wanita
              untuk bahagia dalam kehidupan cinta mereka.
            </p>
            <br />
            <p className="text-center text-white __text-w-22-light">
              Setelah berhasil membantu sang kakak, ia sadar jutaan wanita juga
              punya masalah serupa. Impiannya kini adalah membantu 1 juta wanita
              untuk bahagia dalam kehidupan cinta mereka.
            </p>
            <div className="d-flex justify-content-center py-5">
              <img
                className="img img-fluid"
                width={617}
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/hlg-product.png"
                alt="product hlg"
              />
            </div>
            <p className="text-left text-white __text-w-22-light mx-4">
              Hai Ladies,
              <br />
              <br />
              Untuk kamu wanita single dan <b>ingin bertemu pria yang tepat</b>
              <br />
              <br />
              Dan kamu wanita yang <b>ingin punya kehidupan romansa bahagia</b>
              <br />
              <br />
              Itu semua bisa terjadi dengan buku ini.
              <br />
              <br />
              Kebanyakan buku romansa cuma membahas teknis dan cara instan yang
              bikin wanita gak nyaman mempraktekkannya.
              <br />
              <br />
              Perkenalkan <b>Buku Happy Love Guide</b>
            </p>
          </div>
        </div>
        <div className="container bg-white border">
          <div className="d-flex flex-column pt-5">
            <p className="text-center text-drak __text-w-36">
              Kenapa Buku Ini PENTING <br /> untuk dibaca Oleh Wanita <br />{" "}
              Seperti Kamu?
            </p>
            <div className="my-3">
              <div className="card __border-radius-30 __box-shadow-card py-4 px-4 __w-90 mx-auto">
                {SalesPage.important_read.map((items, index) => (
                  <div className="d-flex flex-row py-1 gap-flex-3" key={index}>
                    <div className="__w-10">
                      <img
                        src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/icon/checklist.png"
                        className="img img-fluid"
                        width={"50px"}
                        height={"50px!important"}
                        alt="icon checklist"
                      />
                    </div>
                    <div
                      className="__w-90"
                      dangerouslySetInnerHTML={{ __html: items.text }}
                    />
                  </div>
                ))}
              </div>
              <div className="d-flex flex-column justify-content-center">
                <button
                  type="button"
                  className="btn btn-danger __border-radius-100 mt-5 py-3 __w-90 mx-auto"
                  onClick={handleClickScrollToPriceList}
                >
                  <span className="px-3 __text-w-22">
                    SAYA MAU PUNYA HAPPY LOVE GUIDE
                  </span>
                </button>
                <p className="__text-w-22-light text-center">
                  Lihat Bonus Apa Saja Yang Kamu Dapat!
                </p>
              </div>
              <div className="my-5 mx-auto">
                <p className="text-center text-dark __text-w-40 pb-3">
                  Yang Akan Kamu Rasakan Setelah Baca Buku Ini
                </p>
                <div className="d-flex justify-content-center my-5">
                  <img
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/mockup-buku-hlg.png"
                    className="img img-fluid"
                    alt="mockup buku"
                  />
                </div>
                <div className="my-5">
                  <div className="card __border-radius-30 __box-shadow-card py-4 px-4 __w-90 mx-auto">
                    {SalesPage.value_book.map((items, index) => (
                      <div
                        className="d-flex flex-row py-1 gap-flex-2"
                        key={index}
                      >
                        <div className="__w-10 text-center">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/icon/checklist-bullet.png"
                            className="img img-fluid"
                            width={"30px"}
                            height={"30px!important"}
                            alt="icon checklist"
                          />
                        </div>
                        <div
                          className="__w-90"
                          dangerouslySetInnerHTML={{ __html: items.text }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-danger __border-radius-100 mt-5 py-3 w-75"
                    onClick={handleClickScrollToPriceList}
                  >
                    <span className="px-3 __text-w-22">YA, SAYA MAU</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container __bg-primary-new-hlg">
          <div className="d-flex flex-column justify-content-center">
            <p className="__text-w-36 text-center text-white pt-5">
              Intip Bocoran “daging” Dari Beberapa Halaman disini
            </p>
            <div className="my-5 d-flex justify-content-center">
              <img
                className="img img-fluid"
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/list-content.jpg"
                alt="daftar isi"
              />
            </div>
            <div className="my-5">
              <div className="card __border-radius-30 __box-shadow-card py-4 px-4 __w-90 mx-auto">
                {SalesPage.listing_value.map((items, index) => (
                  <div className="d-flex flex-row py-1 gap-flex-3" key={index}>
                    <div className="__w-10 text-center">
                      <img
                        src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/icon/checklist.png"
                        className="img img-fluid"
                        width={"50px"}
                        height={"50px!important"}
                        alt="icon checklist"
                      />
                    </div>
                    <div
                      className="__w-90"
                      dangerouslySetInnerHTML={{ __html: items.text }}
                    />
                  </div>
                ))}
                <p className="__text-w-22 py-3">
                  Ditambah saya akan membocorkan…
                </p>
                {SalesPage.listing_value_2.map((items, index) => (
                  <div className="d-flex flex-row py-1 gap-flex-3" key={index}>
                    <div className="__w-10 text-center">
                      <img
                        src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/icon/checklist.png"
                        className="img img-fluid"
                        width={"50px"}
                        height={"50px!important"}
                        alt="icon checklist"
                      />
                    </div>
                    <div
                      className="__w-90"
                      dangerouslySetInnerHTML={{ __html: items.text }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <p className="__text-w-36 text-center text-white pt-5">
              Penasaran? Sini Saya Kasih Intip Beberapa Isinya
            </p>
            <div className="my-4 __w-90 mx-auto">
              <Accordion defaultActiveKey="0">
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <span className="__text-w-24 text-dark">
                        Klik untuk intip isinya...
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      {SalesPage.image_book_part_1.map((items, index) => (
                        <div
                          className="d-flex flex-column justify-content-center my-3"
                          key={index}
                        >
                          <img
                            src={items.image}
                            className="img img-fluid __w-90 mx-auto"
                            alt={`image book` + index}
                          />
                        </div>
                      ))}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      <span className="__text-w-24 text-dark">
                        Klik untuk intip isinya...
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      {SalesPage.image_book_part_2.map((items, index) => (
                        <div
                          className="d-flex flex-column justify-content-center my-3"
                          key={index}
                        >
                          <img
                            src={items.image}
                            className="img img-fluid __w-90 mx-auto"
                            alt={`image book` + index}
                          />
                        </div>
                      ))}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-danger __border-radius-100 mt-5 py-3 w-75"
                  onClick={handleClickScrollToPriceList}
                >
                  <span className="px-3 __text-w-22">YA, SAYA MAU</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-0 m-0 __bg-primary-new-hlg">
          <div className="d-flex justify-content-between w-100">
            <img
              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/aksen/aksen-kiri.png"
              className="img img-fluid"
              alt="akesn kiri"
            />
            <img
              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/aksen/aksen-kanan.png"
              className="img img-fluid"
              alt="akesn kanan"
            />
          </div>
        </div>
        <div className="conatiner bg-white border">
          <div className="d-flex flex-column justify-content-center py-5">
            <p className="text-center text-dark __text-w-50 p-0 m-0">
              Banyak Bonus!
            </p>
            <p className="text-center text-drak __text-w-30">
              <span style={{ background: "#FFD600" }}>
                JIKA PESAN BUKUNYA SEKARANG!
              </span>
            </p>
            {SalesPage.bonus.map((items, index) => (
              <div
                className="d-flex flex-column justify-content-center my-3"
                key={index}
              >
                <img
                  src={items.image}
                  className="img img-fluid w-50 mx-auto"
                  alt="bonus"
                />
                <p className="text-center __text-w-32 pt-3">{items.title}</p>
                <p className="text-center __text-w-22-light __w-90 mx-auto">
                  {items.paragrap}
                </p>
                <p className="text-center __text-w-22">{items.price}</p>
              </div>
            ))}
            <p className="text-center __text-w-36 pt-4">
              Total Senilai : <span className="text-danger">Rp 558.000</span>
            </p>
            <p className="text-center text-dark __text-w-36 mt-5">
              Tenang... <br /> Ada Diskon Khusus Untuk Kamu <br /> Yang Action
              Sekarang
            </p>
            <div className="d-flex justify-content-center my-5">
              <img
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/icon/red-arrow.png"
                className="img img-fluid"
                alt="arrow red"
              />
            </div>
            <p className="text-center text-dark __text-w-50 p-3 m-0">
              Banyak Bonus!
            </p>
            <p className="text-center text-drak __text-w-30">
              <span style={{ background: "#FFD600" }}>
                JIKA PESAN BUKUNYA SEKARANG!
              </span>
            </p>
            <div className="d-flex justify-content-center my-5">
              <img
                src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/mockup-bonus.png"
                className="img img-fluid"
                alt="arrow red"
              />
            </div>
            <div className="my-4 __w-90 mx-auto">
              <div className="d-flex flex-row py-1 gap-flex-2">
                <div className="__w-10 text-center">
                  <img
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/icon/checklist-bullet.png"
                    className="img img-fluid"
                    width={"30px"}
                    height={"30px!important"}
                    alt="icon checklist"
                  />
                </div>
                <div className="__w-90">
                  <p className="__text-w-22 text-dark m-0">
                    <span className="text-danger"> BONUS </span> PDF Cheat Sheet
                  </p>
                  <p className="__text-w-22-light">
                    Rangkuman Visual Buku Happy Love Guide Senilai 129.000
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row py-1 gap-flex-2">
                <div className="__w-10 text-center">
                  <img
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/icon/checklist-bullet.png"
                    className="img img-fluid"
                    width={"30px"}
                    height={"30px!important"}
                    alt="icon checklist"
                  />
                </div>
                <div className="__w-90">
                  <p className="__text-w-22 text-dark m-0">
                    <span className="text-danger"> BONUS </span> PDF LADY Skillset
                  </p>
                  <p className="__text-w-22-light">
                  pembiasaan yang memudahkan Kamu bertemu pria tepat dalam 90 hari atau kurang Senilai 199.000
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row py-1 gap-flex-2">
                <div className="__w-10 text-center">
                  <img
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/icon/checklist-bullet.png"
                    className="img img-fluid"
                    width={"30px"}
                    height={"30px!important"}
                    alt="icon checklist"
                  />
                </div>
                <div className="__w-90">
                  <p className="__text-w-22 text-dark m-0">
                    <span className="text-danger"> BONUS </span> PDF Love Life Blueprint
                  </p>
                  <p className="__text-w-22-light">
                    Senilai 222.000
                  </p>
                </div>
              </div>
            </div>
            <div className="my-3" id="pricehlg">
              <p className="text-center text-dark __text-w-36 m-0">
                Harga Normal <span className="text-danger"> <del>Rp 558.000</del></span>
              </p>
              <p className="text-center text-dark __text-w-36">
               Kamu cukup bayar:
              </p>
              <p className="text-center text-dark pt-4 __text-w-90">
                Rp 229.000
              </p>
              <div className="d-flex justify-content-center">
              {searchParams.utm_source? (
                <>
                <a
                  type="button"
                  className="btn btn-danger __border-radius-100 py-3"
                  href={`https://trulavid.orderonline.id/happy-love-guide?utm_source=${searchParams.utm_source}&utm_medium=${searchParams.utm_medium}&utm_campaign=${searchParams.utm_campaign}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="px-3 __text-w-22">
                    YA, SAYA MAU PUNYA BUKU INI 
                  </span>
                </a>
                </>
              ): (
                <a
                  type="button"
                  className="btn btn-danger __border-radius-100 py-3"
                  href="https://trulavid.orderonline.id/happy-love-guide"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="px-3 __text-w-22">
                    YA, SAYA MAU PUNYA BUKU INI
                  </span>
                </a>
              )}
              </div>
              <p className="text-center text-dark __text-w-22 pt-3">
                <span style={{ background: "#FFD600" }}>
                  Kamu Berhasil Hemat 329 ribu / 60%
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="container __bg-primary-new-hlg">
          <div className="d-flex flex-column justify-content-center py-5">
            <p className="text-center text-white __text-w-36">
              LIHAT DAN RASAKAN APA YANG MEREKA BILANG TENTANG BUKU HAPPY LOVE GUIDE
            </p>
            <div className="d-flex justify-content-center my-3">
              <img src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/kolase-foto-hlg.png" className="img img-fluid" alt="kolase foto hlg"/>
            </div>
            <div className="my-5 __w-90 mx-auto">
              <Accordion defaultActiveKey="3">
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="3">
                      <span className="__text-w-24 text-dark text-left">
                        Klik untuk melihat apa yang mereka bilang...
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      {SalesPage.image_book_part_3.map((items, index) => (
                        <div
                          className="d-flex flex-column justify-content-center my-3"
                          key={index}
                        >
                          <img
                            src={items.image}
                            className="img img-fluid __w-90 mx-auto"
                            alt={`image book` + index}
                          />
                        </div>
                      ))}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="4">
                      <span className="__text-w-24 text-dark text-left">
                        Klik untuk melihat apa yang mereka bilang...
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      {SalesPage.image_book_part_4.map((items, index) => (
                        <div
                          className="d-flex flex-column justify-content-center my-3"
                          key={index}
                        >
                          <img
                            src={items.image}
                            className="img img-fluid __w-90 mx-auto"
                            alt={`image book` + index}
                          />
                        </div>
                      ))}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="5">
                      <span className="__text-w-24 text-dark text-left">
                        Klik untuk melihat apa yang mereka bilang...
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      {SalesPage.image_book_part_5.map((items, index) => (
                        <div
                          className="d-flex flex-column justify-content-center my-3"
                          key={index}
                        >
                          <img
                            src={items.image}
                            className="img img-fluid __w-90 mx-auto"
                            alt={`image book` + index}
                          />
                        </div>
                      ))}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="6">
                      <span className="__text-w-24 text-dark text-left">
                        Klik untuk melihat apa yang mereka bilang...
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body>
                      {SalesPage.image_book_part_6.map((items, index) => (
                        <div
                          className="d-flex flex-column justify-content-center my-3"
                          key={index}
                        >
                          <img
                            src={items.image}
                            className="img img-fluid __w-90 mx-auto"
                            alt={`image book` + index}
                          />
                        </div>
                      ))}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="7">
                      <span className="__text-w-24 text-dark text-left">
                        Klik untuk melihat apa yang mereka bilang...
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      {SalesPage.image_book_part_7.map((items, index) => (
                        <div
                          className="d-flex flex-column justify-content-center my-3"
                          key={index}
                        >
                          <img
                            src={items.image}
                            className="img img-fluid __w-90 mx-auto"
                            alt={`image book` + index}
                          />
                        </div>
                      ))}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <p className="text-center text-white __text-w-36 pt-3">
                DAN MASIH BANYAK BANGET
              </p>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-danger __border-radius-100 mt-5 py-3 w-75"
                  onClick={handleClickScrollToPriceList}
                >
                  <span className="px-3 __text-w-22">YA, SAYA MAU</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-0 m-0 __bg-primary-new-hlg">
          <div className="d-flex justify-content-between w-100">
            <img
              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/aksen/aksen-kiri.png"
              className="img img-fluid"
              alt="akesn kiri"
            />
            <img
              src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/happyloveguide-com/aksen/aksen-kanan.png"
              className="img img-fluid"
              alt="akesn kanan"
            />
          </div>
        </div>
        <div className="container bg-white border">
          <div className="d-flex flex-column justify-content-center py-5">
            <div className="__w-90 mx-auto">
              <VideoModalBs/>
            </div>
            <div className="d-flex justify-content-center my-5">
              <img src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/image-sec-11.png" className="img img-fluid" alt="image video"/>
            </div>
            <p className="text-center text-dark __text-w-22-light">
              Happy Love Guide, buku apa tuh?
            </p>
            <p className="text-center text-dark __text-w-22-light">
              Emangnya ngaruh kalo di baca??
            </p>
            <p className="text-center text-dark __text-w-22-light">
              Halaah cuma teori! Mahal pula!
            </p>
            <p className="text-center text-dark __text-w-22-light">
              Video di bawah, akan menjawab semua pikiran tersebut…
            </p>
            <p className="text-center text-dark __text-w-22-light">
              Happy Love Guide, buku apakah ini?
            </p>
            <p className="text-center text-dark __text-w-22-light">
              Isinya mudah dipraktekan ga? Atau cuma teori doang?
            </p>
            <p className="text-center text-dark __text-w-22-light">
              Dan mengapa <b>BUKU INI GAMPANG SOLD OUT meskipun “mahal”?</b>
            </p>
            <br/>
            <p className="text-center text-drak __text-w-30">
              <span style={{ background: "#FFD600" }}>
                BURUAN! PESAN SEKARANG SEBELUM BONUSNYA HANGUS!
              </span>
            </p>
            <div className="d-flex justify-content-center mt-4">
              <button
                type="button"
                className="btn btn-danger __border-radius-100 py-3 w-75"
                onClick={handleClickScrollToPriceList}
              >
                <span className="px-3 __text-w-22">
                  YA, SAYA MAU
                </span>
              </button>
             </div>
          </div>
        </div>
        <div className="container __bg-secondary-new-hlg">
          <div className="d-flex flex-column justify-content-center py-5 gap-flex-3">
            <p className="text-center text-white __text-w-36">
              PERCUMA CARI PASANGAN KALAU BELUM HAPPY DI DALAM..
            </p>
            <div className="d-flex justify-content-center my-5">
              <img className="img img-fluid __border-radius-20 __w-90 mx-auto" src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/image-section-1-2.jpg" alt="image"/>
            </div>
            <p className="text-center text-white __text-w-22-light">
              Berapa banyak kamu sering dengar Berapa banyak wanita yang merasa hidupnya hampa tanpa pasangan, sepi kalo gak pacaran, namun <b>selalu saja bertemu pria yang memanfaatkannya?</b>
            </p>
            <p className="text-center text-white __text-w-22-light">
              Atau.. <b>jangan-jangan kamu sendiri mengalaminya?</b>
            </p>
            <p className="text-center text-white __text-w-22-light">
              Dengan keadaan seperti ini, gimana kamu bisa punya happy relationship yang happy?
            </p>
            <p className="text-center text-white __text-w-22-light">
              Jawabannya sederhana, kita perlu <b>HAPPY dulu sebelum mulai hubungan.</b>
            </p>
            <p className="text-center text-white __text-w-22-light">
              Buku ini hadir sebagai panduan untuk membawa wanita mewujudkan kehidupan romansa terbaiknya, mulai dari single hingga bertemu pria yang tepat…
            </p>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-danger __border-radius-100 py-3 w-75"
                onClick={handleClickScrollToPriceList}
              >
                <span className="px-3 __text-w-22">
                  YA, SAYA MAU
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="container bg-white border">
          <div className="d-flex flex-column justify-content-center pt-5">
            <p className="text-white text-center text-dark __text-w-36">
              F A Q
            </p>
            <div className="my-5 mx-auto __w-90">
              <Accordion defaultActiveKey="8">
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="8">
                      <span className="__text-w-24 text-dark text-left">
                        Bagaimana cara belinya?
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="8">
                    <Card.Body>
                      <p className="text-left __text-w-22-light">
                        Klik salah satu button pembelian dan kamu akan mengisi data pembelian.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="9">
                      <span className="__text-w-24 text-dark text-left">
                        Kapan saya bisa terima bukunya?
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="9">
                    <Card.Body>
                      <p className="text-left __text-w-22-light">
                        Pengiriman dilakukan jam 11 tiap harinya. Transaksi diluar jam 11 akan dikirim di hari kerja berikutnya. Sabtu & Minggu pengiriman libur.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="10">
                      <span className="__text-w-24 text-dark text-left">
                        Ekspedisi apa yang dipakai? Bagaimana saya melacak buku saya?
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="10">
                    <Card.Body>
                      <p className="text-left __text-w-22-light">
                        Kami menggunakan ekspedisi JNE. Nomor resi akan diinfokan via email.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="11">
                      <span className="__text-w-24 text-dark text-left">
                        Kapan saya bisa terima bonus PDF Cheat Sheet, LADY Skillset & Love Life Blueprint?
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="11">
                    <Card.Body>
                      <p className="text-left __text-w-22-light">
                        Bonus PDF akan diinfokan di dalam paket Buku #HappyLoveGuide yang kamu terima
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="12">
                      <span className="__text-w-24 text-dark text-left">
                        Kapan saya bisa mengakses BONUS Tambahan?
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="12">
                    <Card.Body>
                      <p className="text-left __text-w-22-light">
                        Akses BONUS tambahan ini bisa diakses via member area Trulav, akan diinfokan via email setelah masa Pre Order ditutup mulai 27 Februari 2023. Masa akses bonus tambahan adalah 30 hari.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="13">
                      <span className="__text-w-24 text-dark text-left">
                        Bagaimana saya tahu apakah saya mendapatkan BONUS Tambahan?
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="13">
                    <Card.Body>
                      <p className="text-left __text-w-22-light">
                        Kamu akan menerima email pemberitahuan (maks 3x24jam) setelah melakukan pembayaran
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="14">
                      <span className="__text-w-24 text-dark text-left">
                        Bagaimana jika saya berada di luar negeri dan ingin memesan Happy Love Guide?
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="14">
                    <Card.Body>
                      <p className="text-left __text-w-22-light">
                        Kamu bisa menghubungi tim support kami via Whatsapp 0878-1060-8622 (Desma)
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="14">
                      <span className="__text-w-24 text-dark text-left">
                        Wah jadi gak sabar mau punya Buku Happy Love Guide sekarang!
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="14">
                    <Card.Body>
                      <p className="text-left __text-w-22-light">
                        Yes, saran kami. Segera putuskan beli sebelum waktu habis dan kamu menyesal ketinggalan diskonnya.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="15">
                      <span className="__text-w-24 text-dark text-left">
                        Bagaimana cara pembayarannya?
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="15">
                    <Card.Body>
                      <p className="text-left __text-w-22-light">
                        Kamu bisa pilih transfer manual atau melalui virtual account
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="__border-radius-30">
                  <Card.Header style={{ background: "white" }}>
                    <Accordion.Toggle as={Button} variant="link text-left" eventKey="16">
                      <span className="__text-w-24 text-dark text-left">
                        Masih ada pertanyaan?
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="16">
                    <Card.Body>
                      <p className="text-left __text-w-22-light">
                      Email kami di customer@joseupmind.com atau via WhatsApp: 0878-1060-8622 (Desma)
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
              <p className="text-center text-dark pt-5">
                  © {new Date().getFullYear()}, Built with Lovecoach.id
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Video */}
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        dialogClassName="modal-100w"
        className="w-modal-video"
      >
        <Modal.Header closeButton>
          <Modal.Title>Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Video onSubmit={onCloseClickVideo} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default Index;
