import React, { useState } from "react"
import { Modal, Button } from "react-bootstrap"

const LoginForm = ({ onSubmit }) => {
  return (
    // < !--4: 3 aspect ratio-- >
    <div className="wrape-video-modal">
      <iframe
        src="https://www.youtube.com/embed/Eypl0ryKpF4?autoplay=1&mute=0&enablejsapi=1"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default function Video() {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const onLoginFormSubmit = e => {
    e.preventDefault()
    handleClose()
  }
  return (
    <>
      {/* wrappe image video pop-up */}
      <div className="wrp-video-pop-up-hlg d-flex justify-content-center mrgn-top-100">
        <Button onClick={handleShow}>
          <img
            className="img-fluid"
            style={{borderRadius:"30px"}}
            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/rv.hlg/video-mockup-HLG.jpg"
            alt="video youtube"
          />
        </Button>
      </div>
      {/* end wrappe image video pop-up */}

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        dialogClassName="modal-100w"
        className="w-modal-video"
      >
        <Modal.Header closeButton>
          <Modal.Title>Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoginForm onSubmit={onLoginFormSubmit} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  )
}
